import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-davenport-iowa.png'
import image0 from "../../images/cities/scale-model-of-casi-in-davenport-iowa.png"
import image1 from "../../images/cities/scale-model-of-the-palmer-family-residence-in-davenport-iowa.png"
import image2 from "../../images/cities/scale-model-of-circa-21-speakeasy-in-davenport-iowa.png"
import image3 from "../../images/cities/scale-model-of-putnam-museum-and-science-center-in-davenport-iowa.png"
import image4 from "../../images/cities/scale-model-of-figge-art-museum-in-davenport-iowa.png"
import image5 from "../../images/cities/scale-model-of-german-american-heritage-center-&-museum-in-davenport-iowa.png"
import image6 from "../../images/cities/scale-model-of-john-deere-pavilion-in-davenport-iowa.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Davenport'
            state='Iowa'
            image={image}
            lat='41.5236437'
            lon='-90.57763669999997'
            attractions={ [{"name": "CASI", "vicinity": "1035 W Kimberly Rd, Davenport", "types": ["point_of_interest", "establishment"], "lat": 41.5594541, "lng": -90.58766909999997}, {"name": "The Palmer Family Residence", "vicinity": "808 Brady St, Davenport", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.5285228, "lng": -90.57431910000003}, {"name": "Circa 21 Speakeasy", "vicinity": "1818 3rd Ave, Rock Island", "types": ["night_club", "point_of_interest", "establishment"], "lat": 41.5098099, "lng": -90.57376629999999}, {"name": "Putnam Museum and Science Center", "vicinity": "1717 W 12th St, Davenport", "types": ["museum", "movie_theater", "point_of_interest", "establishment"], "lat": 41.5308793, "lng": -90.60233470000003}, {"name": "Figge Art Museum", "vicinity": "225 W 2nd St, Davenport", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.5205266, "lng": -90.57633570000002}, {"name": "German American Heritage Center & Museum", "vicinity": "712 W 2nd St, Davenport", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.521405, "lng": -90.58359899999999}, {"name": "John Deere Pavilion", "vicinity": "1400 River Dr, Moline", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.5075385, "lng": -90.51788169999998}] }
            attractionImages={ {"CASI":image0,"The Palmer Family Residence":image1,"Circa 21 Speakeasy":image2,"Putnam Museum and Science Center":image3,"Figge Art Museum":image4,"German American Heritage Center & Museum":image5,"John Deere Pavilion":image6,} }
       />);
  }
}